import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, addDoc, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import './AdminPanelReviewSection.css';

const AdminPanelReviewSection = () => {
  const [reviews, setReviews] = useState([]);
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [date, setDate] = useState('');
  const [editId, setEditId] = useState(null);

  // Fetch reviews
  const fetchReviews = async () => {
    const reviewsCollection = collection(db, 'reviews');
    const reviewsSnapshot = await getDocs(reviewsCollection);
    const reviewsList = reviewsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReviews(reviewsList);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  // Add or Update review
  const handleAddOrUpdateReview = async (e) => {
    e.preventDefault();
    if (editId) {
      // Update the review
      const reviewRef = doc(db, 'reviews', editId);
      await updateDoc(reviewRef, { name, text, date });
    } else {
      // Add new review
      await addDoc(collection(db, 'reviews'), { name, text, date });
    }
    setName('');
    setText('');
    setDate('');
    setEditId(null);
    fetchReviews(); // Refresh the reviews list
  };

  // Delete review
  const handleDeleteReview = async (id) => {
    await deleteDoc(doc(db, 'reviews', id));
    fetchReviews(); // Refresh the reviews list after deletion
  };

  // Load review for editing
  const handleEditReview = (review) => {
    setName(review.name);
    setText(review.text);
    setDate(review.date);
    setEditId(review.id);
  };

  return (
    <div className="admin-review-section">
      <h2>Manage Reviews</h2>
      <form onSubmit={handleAddOrUpdateReview}>
        <input 
          type="text" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          placeholder="Name" 
          required 
        />
        <textarea 
          value={text} 
          onChange={(e) => setText(e.target.value)} 
          placeholder="Review Text" 
          required 
        />
        <input 
          type="date" 
          value={date} 
          onChange={(e) => setDate(e.target.value)} 
          required 
        />
        <button className='add-button' type="submit">{editId ? "Update Review" : "Add Review"}</button>
      </form>
      <ul className="review-list">
        {reviews.map(review => (
          <li key={review.id}>
            <h3>{review.name}</h3>
            <button className='edit-button' onClick={() => handleEditReview(review)}>Edit</button>
            <button className='delete-button' onClick={() => handleDeleteReview(review.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPanelReviewSection;
