import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Firebase config
import "./TrendingDestinations.css";
import { Link } from "react-router-dom";

const TrendingDestinationsPage = () => {
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'destinations'));
        const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDestinations(data);
      } catch (error) {
        console.error("Error fetching destinations: ", error);
      }
    };
    fetchDestinations();
  }, []);

  return (
    <div>
      <h2>Trending Destinations</h2>
      <p className='trending-text'>
        Explore the must-visit locations that are captivating travelers worldwide! From the towering peaks of the Himalayas to the serene landscapes of hidden valleys, Nepal offers a journey filled with breathtaking beauty and adventure. Whether you're seeking cultural immersion, thrilling treks, or peaceful retreats, these trending destinations are perfect for every explorer.
      </p>
      <div className="destinations-list">
        {destinations.length > 0 ? (
          destinations.map(destination => (
            <div key={destination.id} className="destination-card">
              <img src={destination.imageUrl} alt={destination.title} />
              <h3>{destination.title}</h3>
              <p>{destination.description.slice(0, 70)}... </p>
              <Link to={`/trending/${destination.id}`} className="read-more">Read More</Link>
            </div>
          ))
        ) : (
          <p>No trending destinations available.</p>
        )}
      </div>
    </div>
  );
};

export default TrendingDestinationsPage;
