import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import './Contact.css'
import { FaWhatsapp } from 'react-icons/fa';
import Logo from '../Assets/Logo/componylogo.jpeg'

const Header = () => {
    return (
        <header className="header">
            <div className="logo">
                <Link to="/">
                    <img src={Logo} alt="Blissful Himalaya" />
                </Link>
                <Link to="/">
                 <p className='logoname'><span>Blissfull</span> Himalaya</p>
                </Link>
            </div>
            <nav className="nav-links">
                <Link to="/articles">Articles</Link>
                <Link to="/packages">Packages</Link>
                <Link to="/events">Events</Link>
                <Link to="/blogs">Blogs</Link>
                <Link to="/gallery">Gallery</Link>
                <Link to="/about">About</Link>

                <div className='contact'>
                    <a
                        href="https://wa.me/9779862253793?text=Hello%20Blissful%20Himalaya%2C%20I%20would%20like%20to%20inquire%20about%20your%20services."
                        target="_blank"
                        rel="noopener noreferrer"
                        className='whatsapp-link'
                    >
                        <FaWhatsapp size={24} color="#25D366" />  {/* WhatsApp Icon */}
                    </a>
                </div>
            </nav>

            <div className="auth-links">
                <Link to="/login">Login</Link>
                <Link to="/signup" className="signup-btn">Sign Up</Link>
            </div>
        </header>
    );
};

export default Header;
