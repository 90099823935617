import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; // Importing react-slick for carousel
import './BlogSection.css';

const BlogSection = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            const blogsCollection = collection(db, 'blogs');
            const blogSnapshot = await getDocs(blogsCollection);
            const blogList = blogSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setBlogs(blogList);
        };

        fetchBlogs();
    }, []);

    // Slick slider settings
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <div className="blog-container">
            {/* Top Section - Carousel */}
            <div className="carousel-section">
                <Slider {...sliderSettings}>
                    {blogs.map((blog) => (
                        <div key={blog.id} className="slider-item">
                            <img src={blog.image} alt={blog.title} className="slider-image" />
                            <div className="slider-content">
                                <h2>{blog.title}</h2>
                                <p>{blog.description.slice(0, 200)}...</p>
                                <Link to={`/blogs/${blog.id}`} className="read-more">Read More</Link>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Bottom Section - Left and Right */}
            <div className="bottom-section">
                {/* Left Section */}
                <div className="left-section">
                    <div className="blog-section">
                        <h2 className="section-title">Featured Blogs</h2>
                        <div className="blog-list">
                            {blogs.map((blog) => (
                                <div key={blog.id} className="blog-card">
                                    <img className="blog-imgage" src={blog.image} alt='blogimage'></img>
                                    <div className="blog-info">
                                        <h3 className="blogtitle">{blog.title}</h3>
                                        <p className="blog-description">
                                            {blog.description.slice(0, 100)}...
                                        </p>
                                        <Link to={`/blogs/${blog.id}`} className="read-more">Read More</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Right Section */}
                <div className="right-section">
                    <h3>Recent Blogs</h3>
                    <div className="blog-list-right">
                        {blogs.slice(0, 8).map((blog) => (
                            <div key={blog.id} className="blog-item-right">
                                <Link to={`/blogs/${blog.id}`} className="blog-title-right">{blog.title}</Link>
                                <span className="blog-date">{new Date(blog.date).toLocaleDateString()}</span>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default BlogSection;
