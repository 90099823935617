import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom'; 
import './TrendingDetailPage.css';

function TrendingDetailPage() {
  const { id } = useParams(); // Get the ID from the URL
  const [trendingItem, setTrendingItem] = useState(null);


  useEffect(() => {
    const fetchTrendingItem = async () => {
      try {
        const docRef = doc(db, 'destinations', id); // Use 'destinations' collection
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setTrendingItem({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching trending item: ", error);
      }
    };

    fetchTrendingItem();
  }, [id]);

  if (!trendingItem) {
    return <div>Loading...</div>; // Loading state
  }

  return (
    <div className="trending-detail-page">
      <h1>{trendingItem.title}</h1>
      <img src={trendingItem.imageUrl} alt={trendingItem.title} className="trending-image" />
      <p className="trending-description">{trendingItem.description}</p>
      <p className="trending-date">
        Location: {trendingItem.location}
      </p>
    </div>
  );
}

export default TrendingDetailPage;
