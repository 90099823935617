import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Firebase config
import './HighlightDetail.css'; // You can style it separately

const HighlightDetailPage = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [highlight, setHighlight] = useState(null);

  useEffect(() => {
    console.log('Highlight ID from URL:', id); // Check if the ID is correct
    const fetchHighlightDetail = async () => {
      try {
        const docRef = doc(db, 'highlights', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setHighlight(docSnap.data());
        } else {
          console.log('No Highlights Found!');
        }
      } catch (error) {
        console.error('Error fetching highlight:', error);
      }
    };
    fetchHighlightDetail();
  }, [id]);

  if (!highlight) {
    return <div>Loading...</div>; // Optional loading state
  }

  return (
    <div className="highlight-detail">
      <h2>{highlight.title}</h2>
      <img src={highlight.imageUrl} alt={highlight.title} />
      <p>{highlight.description}</p>
      <p>Season: {highlight.season}</p>
    </div>
  );
};

export default HighlightDetailPage;
