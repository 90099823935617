import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // Adjust the path as necessary
import ProtectedRoute from './ProtectedRoute'; // Adjust the path as necessary
import Signup from './Component/Signup';
import Login from './Component/Login';
import Logout from './Component/Logout';
import AdminPanel from './Admin/AdminPanel';
import Home from './Home/Home';
import Articles from './Admin/Articles'; 
import Packages from './Admin/Packages'; 
import Blogs from './Admin/Blogs'; 
import Galleries from './Admin/Galleries';
import Events from './Admin/Events'; 
import AboutUs from './AboutUs/AboutUs';
import Header from './Component/Header';
import Footer from './Component/Footer';
import BlogSection from './Blogs/BlogSection';
import BlogDetail from './Blogs/BlogDetail';
import GalleryList from './Gallery/GalleryList';
import EventList from './Events/EventsList';
import PackageList from './Packages/PackageList';
import PackageDetail from './Packages/PackageDetail';
import AdminSeasonalHighlights from './Admin/AdminSeasonalHighlights';
import AdminTrendingDestinations from './Admin/AdminTrendingDestinations';
import HighlightDetailPage from './Home/HighlightDetailPage';
import AdminTeamPanel from './Admin/AdminTeamPanel';
import ArticleList from './Articles/ArticleList';
import ArticleDetail from './Articles/ArticleDetail';
import AdminPanelReviewSection from './Admin/AdminPanelReviewSection';
import TrendingDetailPage from './Home/TrendingDetailPage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path='/login' element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blogs" element={<BlogSection />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/gallery" element={<GalleryList />} />
          <Route path="/events" element={<EventList />} />
          <Route path="/packages" element={<PackageList />} />
          <Route path="/packages/:id" element={<PackageDetail />} />
          
          {/* Protected Admin Routes */}
          <Route path="/admin" element={<ProtectedRoute><AdminPanel /></ProtectedRoute>} />
          <Route path="admin/articles" element={<ProtectedRoute><Articles /></ProtectedRoute>} />
          <Route path="admin/packages" element={<ProtectedRoute><Packages /></ProtectedRoute>} />
          <Route path="admin/blogs" element={<ProtectedRoute><Blogs /></ProtectedRoute>} />
          <Route path="admin/galleries" element={<ProtectedRoute><Galleries /></ProtectedRoute>} />
          <Route path="admin/events" element={<ProtectedRoute><Events /></ProtectedRoute>} />
          <Route path="admin/seasonalHighlights" element={<ProtectedRoute><AdminSeasonalHighlights/></ProtectedRoute>} />
          <Route path="admin/trendingDestinations" element={<ProtectedRoute><AdminTrendingDestinations/></ProtectedRoute>} />
          <Route path="admin/team" element={<ProtectedRoute><AdminTeamPanel /></ProtectedRoute>} />
          <Route path="/admin/reviews" element={<ProtectedRoute><AdminPanelReviewSection /></ProtectedRoute>} />

          <Route path="/highlights/:id" element={<HighlightDetailPage />} />
          <Route path="/articles" element={<ArticleList />} />
          <Route path="/articles/:id" element={<ArticleDetail />} />
          <Route path="trending/:id" element={<TrendingDetailPage />} />
          
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
