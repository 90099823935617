import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Firebase config
import './AdminSeasonalHighlights.css'; // Import CSS file

const AdminSeasonalHighlights = () => {
  const [highlights, setHighlights] = useState([]);
  const [newHighlight, setNewHighlight] = useState({ title: '', description: '', season: '', imageUrl: '' });

  // Fetch existing highlights
  useEffect(() => {
    const fetchHighlights = async () => {
      const querySnapshot = await getDocs(collection(db, 'highlights'));
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHighlights(data);
    };
    fetchHighlights();
  }, []);

  // Add new highlight
  const addHighlight = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, 'highlights'), { ...newHighlight, createdAt: new Date() });
    setNewHighlight({ title: '', description: '', season: '', imageUrl: '' });
  };

  // Delete a highlight
  const deleteHighlight = async (id) => {
    await deleteDoc(doc(db, 'highlights', id));
    setHighlights(highlights.filter(highlight => highlight.id !== id));
  };

  return (
    <div className="admin-highlights">
      <h2 className="admin-title">Manage Seasonal Highlights</h2>
      <form className="highlight-form" onSubmit={addHighlight}>
        <input
          className="input-field"
          type="text"
          placeholder="Title"
          value={newHighlight.title}
          onChange={(e) => setNewHighlight({ ...newHighlight, title: e.target.value })}
          required
        />
        <textarea
          className="textarea-field"
          placeholder="Description"
          value={newHighlight.description}
          onChange={(e) => setNewHighlight({ ...newHighlight, description: e.target.value })}
          required
        ></textarea>
        <input
          className="input-field"
          type="text"
          placeholder="Season (e.g., Winter, Summer)"
          value={newHighlight.season}
          onChange={(e) => setNewHighlight({ ...newHighlight, season: e.target.value })}
          required
        />
        <input
          className="input-field"
          type="text"
          placeholder="Image URL"
          value={newHighlight.imageUrl}
          onChange={(e) => setNewHighlight({ ...newHighlight, imageUrl: e.target.value })}
          required
        />
        <button className="add-button" type="submit">Add Highlight</button>
      </form>

      <ul className="highlights-list">
        {highlights.map(highlight => (
          <li className="highlight-item" key={highlight.id}>
            <h3 className="highlight-title">{highlight.title}</h3>
            <button className="delete-button" onClick={() => deleteHighlight(highlight.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminSeasonalHighlights;
