import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import './ArticleDetail.css';

function ArticleDetail() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      const articleRef = doc(db, 'articles', id);
      const articleSnap = await getDoc(articleRef);
      if (articleSnap.exists()) {
        setArticle(articleSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) return <p>Loading...</p>;

  return (
    <div className="article-detail">
      <div className="article-header">
        <h2>{article.title}</h2>
        <p className="article-date">{new Date(article.date).toLocaleDateString()}</p>
      </div>

      <div className="article-content">
        <img className="article-main-image" src={article.image} alt={article.title} />
        <p>{article.description}</p>

        {article.secondImage && (
          <div className="secondary-content">
            <img className="article-secondary-image" src={article.secondImage} alt="Additional view" />
            <p>{article.remainingDescription}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ArticleDetail;
