import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import './PackageDetail.css'; // Create CSS file for styling

const PackageDetail = () => {
  const { id } = useParams(); // Get the package ID from the URL
  const [packageDetail, setPackageDetail] = useState(null);

  useEffect(() => {
    const fetchPackageDetail = async () => {
      try {
        const packageRef = doc(db, "packages", id);
        const packageSnapshot = await getDoc(packageRef);
        if (packageSnapshot.exists()) {
          setPackageDetail(packageSnapshot.data());
        } else {
          console.error("No package found!");
        }
      } catch (error) {
        console.error("Error fetching package detail:", error);
      }
    };

    fetchPackageDetail();
  }, [id]);

  if (!packageDetail) {
    return <p>Loading...</p>;
  }

  const { name, description, image, price, duration, startPoint, endPoint, itinerary } = packageDetail;

  return (
    <div className="package-detail">
      <h2 className="package-detail-title">{name}</h2>
      <img src={image} alt={name} className="package-detail-image" />
      <p className="package-detail-description">{description}</p>
      <div className="package-detail-info">
        <p><strong>Price:</strong> {price}</p>
        <p><strong>Duration:</strong> {duration} days</p>
        <p><strong>Start Point:</strong> {startPoint}</p>
        <p><strong>End Point:</strong> {endPoint}</p>
      </div>
      <h3 className="package-detail-itinerary-title">Itinerary:</h3>
      <ul className="package-detail-itinerary">
        {(itinerary || []).map((day, idx) => (
          <li key={idx}>Day {idx + 1}: {day}</li>
        ))}
      </ul>
    </div>
  );
};

export default PackageDetail;
