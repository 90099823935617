import React, { useState, useEffect } from 'react';
import './AboutUs.css';
import { db } from '../firebaseConfig'; 
import { collection, getDocs } from 'firebase/firestore'; 

function AboutUs() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [vacancies, setVacancies] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const teamMembersCollection = collection(db, 'teamMembers');
      const teamSnapshot = await getDocs(teamMembersCollection);
      const teamList = teamSnapshot.docs.map(doc => doc.data());
      setTeamMembers(teamList);
    };

    const fetchVacancies = async () => {
      const vacanciesCollection = collection(db, 'vacancies');
      const vacanciesSnapshot = await getDocs(vacanciesCollection);
      const vacancyList = vacanciesSnapshot.docs.map(doc => doc.data());
      setVacancies(vacancyList);
    };

    fetchTeamMembers();
    fetchVacancies();
  }, []);

  return (
    <div className="about-us">
      <div className="about-us-container">
        <section className="about-us-intro">
          <h1>About Us</h1>
          <p>Get to know our team and our mission</p>
          <p>
            At Blissful Himalaya, we are dedicated to providing exceptional travel experiences across Nepal. Our team of passionate travel experts is committed to delivering authentic and unforgettable journeys, helping you explore the most stunning landscapes and vibrant cultures. Learn more about our story and the people behind the adventures.
          </p>
        </section>

        <section className="about-us-mission">
          <h2>Our Mission</h2>
          <p>
            Blissful Himalaya is an open team of spiritual knowledge, professionalism in sustainable tourism practice, and perfect planning and operation. We hire experienced in-house operators with field experience and emerging technical management capacity. Our team, local tourism professionals, is ready to respond to your queries about well-managed and reliable trek/tour experts. Our work is more than a job; it reflects our family bond. We are well-trained, friendly, and experienced in every corner of Nepal, having researched the geography, mountains, wildlife, and overall biodiversity. Our major goal is to provide information for travelers and organize unique travel experiences through our services. We prepare tailored itineraries for a hassle-free travel experience.
          </p>
        </section>

        <section className="about-us-team">
          <h2>Meet Our Team</h2>
          <div className="about-us-team-members">
            {teamMembers.length > 0 ? (
              teamMembers.map((member, index) => (
                <div key={index} className="about-us-team-member">
                  <h3>{member.name}</h3>
                  <p className="about-us-role">{member.role}</p>
                  <p>{member.bio}</p>
                </div>
              ))
            ) : (
              <p>No team members available.</p>
            )}
          </div>
        </section>

        <section className="about-us-vacancies">
          <h2>Job Vacancies</h2>
          <div className="about-us-vacancies-list">
            {vacancies.length > 0 ? (
              vacancies.map((vacancy, index) => (
                <div key={index} className="about-us-vacancy">
                  <h3>{vacancy.position}</h3>
                  <p>{vacancy.description}</p>
                  <p><strong>Location:</strong> {vacancy.location}</p>
                </div>
              ))
            ) : (
              <p>No vacancies available at the moment.</p>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutUs;
