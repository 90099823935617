import React from 'react';
import Banner from '../Component/Banner';
import SeasonalHighlightsPage from './SeasonalHighlightsPage';
import TrendingDestinationsPage from './TrendingDestinationsPage';
import EventsList from '../Events/EventsList';
import LatestBlogs from '../Blogs/LatestBlogs';
import ReviewSection from '../Review/ReviewSection';

const Home = () => {

    return (
        <div>
            <Banner />
            <SeasonalHighlightsPage />
            <TrendingDestinationsPage />
            <EventsList />
            <LatestBlogs />
            <ReviewSection />
        </div>
    );
};

export default Home;
