import React from 'react';
import './EventCard.css';

function EventCard({ event, onViewDetails }) {
  const { image = '', title = 'Untitled', description = 'No description available', date = new Date() } = event || {};

  return (
    <div className="event-card">
      {image && <img src={image} alt={title} className="event-image" />}
      <div className="event-content">
        <h3 className="event-title">{title}</h3>
        <p className="event-date">Date: {new Date(date).toLocaleDateString()}</p>
        <p className="eventdescription">
          {description.length > 100 ? `${description.substring(0, 100)}...` : description}
        </p>
        {/* Trigger the modal with the event data */}
        <button onClick={() => onViewDetails(event)} className="event-button">View Details</button>
      </div>
    </div>
  );
}

export default EventCard;
