import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import './BlogDetail.css';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const BlogDetail = () => {
  const { id } = useParams(); // Get blog ID from the URL
  const [blog, setBlog] = useState(null);
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    const fetchBlog = async () => {
      const blogDoc = doc(db, 'blogs', id);
      const blogData = await getDoc(blogDoc);
      if (blogData.exists()) {
        setBlog(blogData.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchBlog();
  }, [id]);

  const handleLike = () => {
    setLikes(likes + 1);
  };

  const handleDislike = () => {
    setDislikes(dislikes + 1);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (newComment.trim() === '') return;

    setComments([...comments, { text: newComment, date: new Date() }]);
    setNewComment('');
  };

  if (!blog) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="blog-detail">
      <div className="blog-header">
        <h1 className="blog-title">{blog.title}</h1>
        <div className="blog-meta">
          <p>Written by <span className="author-name">{blog.writer}</span> on {blog.date}</p>
        </div>
        <img src={blog.image} alt={blog.title} className="blog-detail-image" />
      </div>

      <div className="blog-content">
        <p>{blog.description}</p>
      </div>

      {/* Like/Dislike Section */}
      <div className="interaction-section">
        <button className="like-btn" onClick={handleLike}>
          <FaThumbsUp /> {likes} Likes
        </button>
        <button className="dislike-btn" onClick={handleDislike}>
          <FaThumbsDown /> {dislikes} Dislikes
        </button>
      </div>

      {/* Comment Section */}
      <div className="comment-section">
        <h3>Comments</h3>
        <form onSubmit={handleCommentSubmit} className="comment-form">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Write a comment..."
            className="comment-input"
            rows="3"
          />
          <button type="submit" className="comment-submit">Post Comment</button>
        </form>

        {/* Display Comments */}
        <div className="comment-list">
          {comments.map((comment, index) => (
            <div key={index} className="comment-item">
              <p>{comment.text}</p>
              <span className="comment-date">{new Date(comment.date).toLocaleString()}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
