import React, { useState, useEffect } from "react";
import { db, storage } from "../firebaseConfig";
import "./Galleries.css";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const Gallery = () => {
  const [imageFile, setImageFile] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  // Fetch gallery images from Firestore
  const fetchGallery = async () => {
    setLoading(true);
    try {
      const galleryCollection = collection(db, "gallery");
      const gallerySnapshot = await getDocs(galleryCollection);
      const galleryList = gallerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGallery(galleryList);
    } catch (error) {
      console.error("Error fetching gallery:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  // Handle image selection
  const handleImageUpload = (e) => {
    setImageFile(e.target.files[0]);
  };

  // Upload image to Firebase Storage and Firestore
  const handleUpload = async () => {
    if (!imageFile) return alert("Please select an image to upload");

    const storageRef = ref(storage, `gallery/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    setUploading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Update progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload error:", error);
        setUploading(false);
      },
      async () => {
        try {
          // Get download URL and save image details to Firestore
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await addDoc(collection(db, "gallery"), {
            name: imageFile.name,
            url: downloadURL,
          });
          setImageFile(null);
          fetchGallery(); // Refresh the gallery
        } catch (error) {
          console.error("Error saving image to Firestore:", error);
        } finally {
          setUploading(false);
          setProgress(0);
        }
      }
    );
  };

  // Delete image from Firebase Storage and Firestore
  const handleDeleteImage = async (id, imageName) => {
    const imageRef = ref(storage, `gallery/${imageName}`);

    try {
      // Delete from Firebase Storage
      await deleteObject(imageRef);

      // Delete image data from Firestore
      await deleteDoc(doc(db, "gallery", id));
      fetchGallery(); // Refresh the gallery
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <div className="gallery-container">
      <h2 className="gallery-header">Admin Gallery</h2>

      {/* Upload Image */}
      <div className="upload-section">
        <input type="file" onChange={handleImageUpload} />
        <button onClick={handleUpload} disabled={uploading}>
          {uploading ? `Uploading... (${Math.round(progress)}%)` : "Upload Image"}
        </button>
      </div>

      {/* Display Gallery */}
      {loading ? (
        <p className="loading-text">Loading gallery...</p>
      ) : (
        <div className="gallery">
          {gallery.length === 0 ? (
            <p>No images uploaded yet.</p>
          ) : (
            gallery.map((item) => (
              <div key={item.id} className="gallery-item">
                <img
                  src={item.url}
                  alt={item.name}
                  loading="lazy"
                />
                <p>{item.name}</p>
                <button onClick={() => handleDeleteImage(item.id, item.name)}>Delete</button>
              </div>
            ))
          )}
        </div>
      )}
    </div>

  );
};

export default Gallery;
