import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore"; // Firestore functions
import './Login.css';  // Import the CSS file for styling

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      // Sign in user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Get user role from Firestore
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        
        // Check if the role is 'admin' and redirect accordingly
        if (userData.role === "admin") {
          navigate("/admin");
        } else {
          navigate("/"); // Redirect regular users to homepage
        }
      } else {
        console.log("No user data found");
      }

    } catch (error) {
      console.error("Error logging in: ", error.message);
      // Handle login error (show message to user)
    }
  };

  return (
    <div className="login">
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-heading">Login</h2>
        <form onSubmit={handleLogin}>
          <input 
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email address"
            className="login-input"
            required
          />
          <input 
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
            className="login-input"
            required
          />
          <button type="submit" className="login-button">LOGIN</button>
        </form>
        <p className="signup-link"><a href="/signup">Sign Up</a></p>
        
      </div>
    </div>
    </div>
  );
};

export default Login;
