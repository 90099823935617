import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebaseConfig'; 
import './Events.css'; // Import the CSS file

const Events = () => {
  const [events, setEvents] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [place, setPlace] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch events on component mount
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, 'events');
        const eventSnapshot = await getDocs(eventsCollection);
        const eventsList = eventSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setEvents(eventsList);
      } catch (error) {
        console.error("Error fetching events: ", error);
      }
    };
    fetchEvents();
  }, []);

  // Handle image upload and return download URL
  const handleImageUpload = () => {
    return new Promise((resolve, reject) => {
      if (!image) {
        resolve(''); // No image, resolve with an empty string
        return;
      }

      const storageRef = ref(storage, `events/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        null, // No progress updates
        (error) => {
          reject(error); // Reject the promise on error
        },
        async () => {
          // Get the download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL); // Resolve with the download URL
        }
      );
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const image = await handleImageUpload();
      const eventData = { title, description, date, place, image: image };

      // Add the event to Firestore
      await addDoc(collection(db, 'events'), eventData);

      // Clear form fields
      setTitle('');
      setDescription('');
      setDate('');
      setPlace('');
      setImage(null);
    } catch (error) {
      console.error("Error adding event: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle event deletion
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'events', id));
      setEvents(events.filter(event => event.id !== id)); // Remove deleted event from state
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };

  return (
    <div className="events-wrapper">
      <h2 className="events-header">Manage Events</h2>

      <form onSubmit={handleSubmit} className="event-form">
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Place"
          value={place}
          onChange={(e) => setPlace(e.target.value)}
          required
        />
        <input
          type="file"
          accept="image/*" // Restrict file types to images
          onChange={(e) => setImage(e.target.files[0])}
          required
        />
        <button className='add-button' type="submit" disabled={loading}>
          {loading ? 'Uploading...' : 'Add Event'}
        </button>
      </form>

      <div className="events-list">
        {events.map(event => (
          <div key={event.id} className="event-card">
            <img src={event.image} alt={event.title} />
            <h3>{event.title}</h3>
            <div className="event-info">
              <p>{new Date(event.date).toDateString()}</p>
              <p>{event.place}</p>
            </div>
            <button className='delete-button' onClick={() => handleDelete(event.id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;
