// src/components/PackageCard.js
import React from "react";
import { Link } from "react-router-dom";
import './PackageCard.css'; // Create CSS file for styling

const PackageCard = ({ packageData }) => {
  const { id, name, image, price, duration } = packageData;

  return (
    <div className="package-card">
      <div className="image-container">
        <img src={image} alt={name} className="package-image" />
      </div>
      <div className="package-content">
        <h3 className="package-title">{name}</h3>
        <div className="package-details">
          <div className="package-info">
            <p className="package-price">Price: ${price}</p>
            <p className="package-duration">Duration: {duration} days</p>
          </div>
          <div className="view-details-container">
            <Link to={`/packages/${id}`}>
              <button className="view-details-button">View Details</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
