// ArticleList.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './ArticleList.css';


function ArticleList() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesCollection = collection(db, 'articles');
      const articlesSnapshot = await getDocs(articlesCollection);
      const articlesList = articlesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setArticles(articlesList);
    };

    fetchArticles();
  }, []);

  return (
    <div className="article-list">
      <h2>Articles</h2>
      <div className="article-items">
        {articles.length > 0 ? (
          articles.map(article => (
            <div key={article.id} className="article-item">
                <h3>{article.title}</h3>
                <img src={article.image} alt={article.title} />
                <p>{article.description.slice(0,100)}...</p>
              <Link to={`/articles/${article.id}`} className="read-more">Read More</Link>
            </div>
          ))
        ) : (
          <p>No articles available.</p>
        )}
      </div>
    </div>
  );
}

export default ArticleList;
