import React from "react";
import { Outlet, Link } from "react-router-dom";
import './AdminPanel.css'; // Import the CSS file

const AdminPanel = () => {
  return (
    <div className="admin-panel"> {/* Apply the admin-panel class */}
      <h1>Welcome To Admin Panel</h1>
      <nav>
        <ul>
          <li><Link to="articles">Manage Articles</Link></li>
          <li><Link to="packages">Manage Packages</Link></li>
          <li><Link to="blogs">Manage Blogs</Link></li>
          <li><Link to="galleries">Manage Galleries</Link></li>
          <li><Link to="events">Manage Events</Link></li>
          <li><Link to="reviews">Manage Reviews</Link></li>
          <li><Link to="seasonalHighlights">Manage Seasonal Highlights</Link></li>
          <li><Link to="trendingDestinations">Manage Trending Destinations</Link></li>
          <li><Link to="team">Manage Team</Link></li>
        </ul>
      </nav>
      <hr />
      <Outlet /> {/* This is where the nested components will render */}
    </div>
  );
};

export default AdminPanel;
