// Modal.js
import React from 'react';
import './Modal.css';

function Modal({ showModal, handleClose, event }) {
  if (!showModal) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">{event.title}</h2>
        {event.image && <img src={event.image} alt={event.title} className="modal-image" />}
        <p className="modal-date">Date: {new Date(event.date).toLocaleDateString()}</p>
        <p className="modal-description">{event.description}</p>
        <button onClick={handleClose} className="close-button">Close</button>
      </div>
    </div>
  );
}

export default Modal;
