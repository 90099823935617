// src/Admin/Blogs.js
import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; // Firebase configuration
import { collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore"; // Import necessary Firestore functions
import './Blogs.css'; // Import the CSS for Blogs

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [date, setDate] = useState("");
  const [writer, setWriter] = useState("");
  // const [commentList, setCommentList] = useState({});

  // Fetch blogs from Firestore
  const fetchBlogs = async () => {
    const blogsCollection = collection(db, "blogs");
    const blogsSnapshot = await getDocs(blogsCollection);
    const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setBlogs(blogsList);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  // Add new blog
  const handleAddBlog = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "blogs"), {
      title,
      description,
      image,
      date,
      writer,
      likes: 0, // Initialize likes
      comments: [], // Initialize comments
    });
    setTitle("");
    setDescription("");
    setImage("");
    setDate("");
    setWriter("");
    fetchBlogs(); // Refresh the list after adding
  };

  // Delete blog
  const handleDeleteBlog = async (id) => {
    await deleteDoc(doc(db, "blogs", id));
    fetchBlogs(); // Refresh the list after deletion
  };

  // Like blog
  // const handleLike = async (id) => {
  //   const blogRef = doc(db, "blogs", id);
  //   const blogData = blogs.find(blog => blog.id === id);
  //   await updateDoc(blogRef, { likes: blogData.likes + 1 });
  //   fetchBlogs(); // Refresh the list after liking
  // };

  // Add comment
  // const handleAddComment = async (id) => {
  //   const blogRef = doc(db, "blogs", id);
  //   const blogData = blogs.find(blog => blog.id === id);
  //   const newCommentList = [...blogData.comments, commentList[id]];
    
  //   await updateDoc(blogRef, { comments: newCommentList });
  //   setCommentList({ ...commentList, [id]: "" }); // Clear input after adding
  //   fetchBlogs(); // Refresh the list after adding comment
  // };

  return (
    <div className="blogs-container">
      <h2 className="blogs-header">Manage Blogs</h2>
      <form className="blog-form" onSubmit={handleAddBlog}>
        <input 
          className="input-field" 
          type="text" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
          placeholder="Blog Title" 
          required 
        />
        <textarea 
          className="textarea-field" 
          value={description} 
          onChange={(e) => setDescription(e.target.value)} 
          placeholder="Blog Description" 
          required 
        />
        <input 
          className="input-field" 
          type="text" 
          value={image} 
          onChange={(e) => setImage(e.target.value)} 
          placeholder="Image URL" 
          required 
        />
        <input 
          className="input-field" 
          type="text" 
          value={writer} 
          onChange={(e) => setWriter(e.target.value)} 
          placeholder="Writer" 
          required 
        />
        <input 
          className="input-field" 
          type="date" 
          value={date} 
          onChange={(e) => setDate(e.target.value)} 
          required 
        />
        <button className="submit-button" type="submit">Add Blog</button>
      </form>
      <ul className="blogs-list">
        {blogs.map(blog => (
          <li key={blog.id} className="blog-item">
            <h3>{blog.title}</h3>
            <img src={blog.image} alt={blog.title} />
            <p className="blog-info">Date: {blog.date}</p>
            <p className="blog-info">Writer: {blog.writer}</p>
            <p className="blog-info">Likes: {blog.likes}</p>
            <button className="delete-button" onClick={() => handleDeleteBlog(blog.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blog;
