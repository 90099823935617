import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Adjust the path to your firebase config file
import "./GalleryList.css"; // Custom CSS file for styling

const GalleryList = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  // Fetch gallery images from Firestore
  const fetchGallery = async () => {
    try {
      const galleryCollection = collection(db, "gallery");
      const gallerySnapshot = await getDocs(galleryCollection);
      const galleryList = gallerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGalleryItems(galleryList);
    } catch (error) {
      console.error("Error fetching gallery:", error);
    }
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  // Function to handle image click
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Function to close the popup
  const closePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-list">
      <h2>Gallery</h2>
      <div className="gallery-grid">
        {galleryItems.map((item) => (
          <div key={item.id} className="gallery-item">
            <img
              src={item.url}
              alt={item.name}
              onClick={() => handleImageClick(item.url)}
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {/* Popup Modal for the selected image */}
      {selectedImage && (
        <div className="image-popup" onClick={closePopup}>
          <div className="popup-content">
            <span className="close-btn" onClick={closePopup}>&times;</span>
            <img src={selectedImage} alt="Selected" />
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryList;
