import React, { Component } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Firebase config
import './AdminTrendingDestinations.css'; // Import the CSS file

class AdminTrendingDestinations extends Component {
  state = {
    destinations: [],
    newDestination: { title: '', description: '', location: '', imageUrl: '' },
  };

  componentDidMount() {
    this.fetchDestinations();
  }

  // Fetch existing destinations
  fetchDestinations = async () => {
    const querySnapshot = await getDocs(collection(db, 'destinations'));
    const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    this.setState({ destinations: data });
  };

  // Add new destination
  addDestination = async (e) => {
    e.preventDefault();
    const { newDestination } = this.state;
    await addDoc(collection(db, 'destinations'), { ...newDestination, createdAt: new Date() });
    this.setState({
      newDestination: { title: '', description: '', location: '', imageUrl: '' },
    });
    this.fetchDestinations(); // Refresh the destinations list
  };

  // Delete a destination
  deleteDestination = async (id) => {
    await deleteDoc(doc(db, 'destinations', id));
    this.setState({
      destinations: this.state.destinations.filter(destination => destination.id !== id),
    });
  };

  // Handle form input change
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      newDestination: { ...prevState.newDestination, [name]: value },
    }));
  };

  render() {
    const { destinations, newDestination } = this.state;

    return (
      <div className="admin-trending-destinations">
        <h2>Manage Trending Destinations</h2>
        <form className="destination-form" onSubmit={this.addDestination}>
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={newDestination.title}
            onChange={this.handleChange}
            required
          />
          <textarea
            name="description"
            placeholder="Description"
            value={newDestination.description}
            onChange={this.handleChange}
            required
          ></textarea>
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={newDestination.location}
            onChange={this.handleChange}
            required
          />
          <input
            type="text"
            name="imageUrl"
            placeholder="Image URL"
            value={newDestination.imageUrl}
            onChange={this.handleChange}
            required
          />
          <button type="submit">Add Destination</button>
        </form>

        <ul className="destination-list">
          {destinations.map(destination => (
            <li key={destination.id} className="destination-item">
              <h3>{destination.title}</h3>
              <p>{destination.location}</p>
              <button onClick={() => this.deleteDestination(destination.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default AdminTrendingDestinations;
