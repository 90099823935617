import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; // Firebase configuration
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import "./Articles.css";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [secondImage, setSecondImage] = useState("");
  const [remainingDescription, setRemainingDescription] = useState(""); // New state for remaining description
  const [date, setDate] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);

  // Fetch articles from Firestore
  const fetchArticles = async () => {
    const articlesCollection = collection(db, "articles");
    const articlesSnapshot = await getDocs(articlesCollection);
    const articlesList = articlesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setArticles(articlesList);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  // Add or update article
  const handleAddOrUpdateArticle = async (e) => {
    e.preventDefault();
    if (isEditing) {
      const articleRef = doc(db, "articles", currentArticleId);
      await updateDoc(articleRef, {
        title,
        description,
        image,
        secondImage,
        remainingDescription,
        date,
      });
      setIsEditing(false);
      setCurrentArticleId(null);
    } else {
      await addDoc(collection(db, "articles"), {
        title,
        description,
        image,
        secondImage,
        remainingDescription,
        date,
      });
    }
    setTitle("");
    setDescription("");
    setImage("");
    setSecondImage("");
    setRemainingDescription("");
    setDate("");
    fetchArticles();
  };

  // Delete article
  const handleDeleteArticle = async (id) => {
    await deleteDoc(doc(db, "articles", id));
    fetchArticles();
  };

  // Edit article (populate the form with existing data)
  const handleEditArticle = (article) => {
    setTitle(article.title);
    setDescription(article.description);
    setImage(article.image);
    setSecondImage(article.secondImage);
    setRemainingDescription(article.remainingDescription);
    setDate(article.date);
    setCurrentArticleId(article.id);
    setIsEditing(true);
  };

  return (
    <div className="articles-container">
      <h2 className="articles-header">{isEditing ? "Edit Article" : "Manage Articles"}</h2>
      <form className="article-form" onSubmit={handleAddOrUpdateArticle}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Article Title"
          required
          className="article-input"
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Article Description"
          required
          className="article-textarea"
        />
        <input
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          placeholder="First Image URL"
          required
          className="article-input"
        />
        <input
          type="text"
          value={secondImage}
          onChange={(e) => setSecondImage(e.target.value)}
          placeholder="Second Image URL"
          required
          className="article-input"
        />
        <textarea
          value={remainingDescription}
          onChange={(e) => setRemainingDescription(e.target.value)}
          placeholder="Remaining Description"
          required
          className="article-textarea"
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
          className="article-input"
        />
        <button type="submit" className="article-button">
          {isEditing ? "Update Article" : "Add Article"}
        </button>
        {isEditing && (
          <button
            className="edit-button"
            type="button"
            onClick={() => {
              setIsEditing(false);
              setTitle("");
              setDescription("");
              setImage("");
              setSecondImage("");
              setRemainingDescription("");
              setDate("");
            }}
          >
            Cancel Edit
          </button>
        )}
      </form>

      <ul className="articles-list">
        {articles.map((article) => (
          <li key={article.id} className="article-item">
            <h3 className="article-title">{article.title}</h3>
            <img src={article.image} alt={article.title} className="article-image" />
            <button onClick={() => handleEditArticle(article)} className="article-button edit-button">
              Edit
            </button>
            <button className="delete-button" onClick={() => handleDeleteArticle(article.id)} >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Articles;
