import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; // Firebase setup
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore'; // Firestore imports
import './AdminTeamPanel.css'; // Import CSS file

function AdminTeamPanel() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [vacancies, setVacancies] = useState([]);
  const [newMember, setNewMember] = useState({ name: '', role: '', bio: '' });
  const [newVacancy, setNewVacancy] = useState({ position: '', description: '', location: '' });

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const teamMembersCollection = collection(db, 'teamMembers');
      const teamSnapshot = await getDocs(teamMembersCollection);
      const teamList = teamSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTeamMembers(teamList);
    };

    const fetchVacancies = async () => {
      const vacanciesCollection = collection(db, 'vacancies');
      const vacanciesSnapshot = await getDocs(vacanciesCollection);
      const vacancyList = vacanciesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVacancies(vacancyList);
    };

    fetchTeamMembers();
    fetchVacancies();
  }, []);

  const handleAddMember = async () => {
    await addDoc(collection(db, 'teamMembers'), newMember);
    setTeamMembers([...teamMembers, newMember]);
    setNewMember({ name: '', role: '', bio: '' });
  };

  const handleDeleteMember = async (id) => {
    if (!id) {
      console.error('No ID provided for deletion');
      return;
    }
  
    try {
      const memberDoc = doc(db, 'teamMembers', id); // Ensure `id` is valid
      await deleteDoc(memberDoc);
      setTeamMembers(teamMembers.filter(member => member.id !== id));
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };
  

  const handleAddVacancy = async () => {
    await addDoc(collection(db, 'vacancies'), newVacancy);
    setVacancies([...vacancies, newVacancy]);
    setNewVacancy({ position: '', description: '', location: '' });
  };

  const handleDeleteVacancy = async (id) => {
    if (!id) {
      console.error('No ID provided for deletion');
      return;
    }
  
    try {
      const vacancyDoc = doc(db, 'vacancies', id); // Ensure `id` is valid
      await deleteDoc(vacancyDoc);
      setVacancies(vacancies.filter(vacancie => vacancie.id !== id));
    } catch (error) {
      console.error("Error deleting vacancies:", error);
    }
  };
  

  return (
    <div className="admin-panel">
      <h1 className="admin-title">Admin Panel</h1>

      <section className="team-section">
        <h2 className="section-title">Add Team Member</h2>
        <input
          className="input-field"
          type="text"
          placeholder="Name"
          value={newMember.name}
          onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
        />
        <input
          className="input-field"
          type="text"
          placeholder="Role"
          value={newMember.role}
          onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
        />
        <textarea
          className="textarea-field"
          placeholder="Bio"
          value={newMember.bio}
          onChange={(e) => setNewMember({ ...newMember, bio: e.target.value })}
        />
        <button className="add-button" onClick={handleAddMember}>Add Member</button>

        <h2 className="section-title">Manage Team Members</h2>
        <ul className="list">
          {teamMembers.map(member => (
            <li className="list-item" key={member.id}>
              {member.name} - {member.role} 
              <button className="delete-button" onClick={() => handleDeleteMember(member.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </section>

      <section className="vacancy-section">
        <h2 className="section-title">Add Job Vacancy</h2>
        <input
          className="input-field"
          type="text"
          placeholder="Position"
          value={newVacancy.position}
          onChange={(e) => setNewVacancy({ ...newVacancy, position: e.target.value })}
        />
        <textarea
          className="textarea-field"
          placeholder="Description"
          value={newVacancy.description}
          onChange={(e) => setNewVacancy({ ...newVacancy, description: e.target.value })}
        />
        <input
          className="input-field"
          type="text"
          placeholder="Location"
          value={newVacancy.location}
          onChange={(e) => setNewVacancy({ ...newVacancy, location: e.target.value })}
        />
        <button className="add-button" onClick={handleAddVacancy}>Add Vacancy</button>

        <h2 className="section-title">Manage Vacancies</h2>
        <ul className="list">
          {vacancies.map(vacancy => (
            <li className="list-item" key={vacancy.id}>
              {vacancy.position} - {vacancy.location} 
              <button className="delete-button" onClick={() => handleDeleteVacancy(vacancy.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default AdminTeamPanel;
