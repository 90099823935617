import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import PackageCard from "./PackageCard"; // Import PackageCard component
import './PackageList.css'; // Create CSS file for styling

const PackageList = () => {
  const [packages, setPackages] = useState([]);

  // Fetch packages from Firestore
  const fetchPackages = async () => {
    const packagesCollection = collection(db, "packages");
    const packagesSnapshot = await getDocs(packagesCollection);
    const packagesList = packagesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPackages(packagesList);
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  return (
    <div className="package-list">
      <h2 className="package-list-title">Available Packages</h2>
      <div className="packages-container">
        {packages.map((pkg) => (
          <PackageCard key={pkg.id} packageData={pkg} />
        ))}
      </div>
    </div>
  );
};

export default PackageList;
