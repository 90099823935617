import React from 'react';
import './Banner.css';
import SearchBar from '../Search/SearchBar';

const Banner = () => {
  
  return (
    <div className="banner">
      {/* Slogan */}
      <div className="banner-slogan">
        <h1>Explore the World with Blissful Himalaya</h1>
        <p>Find Your Perfect Adventure, Blog, or Travel Package</p>
      </div>

      {/* Search Bar */}
      <div className="banner-search">
        <SearchBar />
      </div>
    </div>
  );
};

export default Banner;
