// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAIzAT27tjJn2yDdmsKHaRSchmHImViZ5U",
  authDomain: "blissfullhimalaya.com",
  projectId: "blissfull-67786",
  storageBucket: "blissfull-67786.appspot.com",
  messagingSenderId: "123641516467",
  appId: "1:123641516467:web:12923f2c32f8578cb23bc6",
  measurementId: "G-7CP2QXF2PL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app); // Firebase Authentication
const db = getFirestore(app); // Firestore for database
const storage = getStorage(app); // Firebase Storage for file uploads

// Export Firebase services
export { auth, db, storage };
