import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../Assets/Logo/componylogo.jpeg"
import './Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo Section */}
        <div className="footer-logo">
          <Link to="/">
            <img src={Logo} alt="Blissful Himalaya" />
          </Link>
          <Link to="/">
            <p className='logoname'><span>Blissfull</span> Himalaya</p>
          </Link>
        </div>

        {/* Contact Details Section */}
        <div className="footer-contact">
          <h4>Contact Us</h4>
          <p>Email: info@blissfulhimalaya.com</p>
          <p>Phone: +977 986-2253793</p>
          <p>Address: Kathmandu, Nepal</p>
        </div>

        {/* Navigation Links Section */}
        <div className="footer-links">
          <h4>Quick Links</h4>
          <nav>
            <Link to="/articles">Articles</Link>
            <Link to="/about">About</Link>
            <Link to="/events">Events</Link>
            <Link to="/packages">Packages</Link>
            <Link to="/gallery">Gallery</Link>
            <Link to="/contact">Contact</Link>
          </nav>
        </div>

        {/* Social Media Section */}
        <div className="footer-social">
          <h4>Follow Us</h4>
          <a href="https://www.facebook.com/people/Blissfull-Himalaya/61561183425468/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">Facebook</a>
          <a href="https://www.instagram.com/blissfullhimalaya?igsh=MW1tOGxpbWZsaDFkZA%3D%3D&utm_source=qr" target="_blank" rel="noreferrer">Instagram</a>
          <a href="https://www.youtube.com/@blissfullhimalaya" target="_blank" rel="noreferrer">Youtube</a>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Blissful Himalaya. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
