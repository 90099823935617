import { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore"; // Firestore
import { auth, db } from "../firebaseConfig"; 
import { useNavigate } from "react-router-dom";
import "./Signup.css";  // Assuming the CSS file for the signup page

const Signup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store user data with 'user' role in Firestore
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        role: "user", // Default role for all users
      });

      navigate("/"); // Redirect to homepage after signup

    } catch (error) {
      console.error("Error signing up:", error.message);
    }
  };

  return (
    <div className="signup">
      <div className="signup-container">
        <div className="signup-box">
          <h2 className="signup-heading">Sign Up</h2>
          <form onSubmit={handleSignup}>
            <input 
              type="email" 
              className="signup-input"
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Email" 
            />
            <input 
              type="password" 
              className="signup-input"
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
            />
            <button type="submit" className="signup-button">Sign Up</button>
          </form>
          <p className="login-link">Already have an account? <a href="/login">Log in</a></p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
