import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; 
import EventCard from './EventCard';
import Modal from './Modal'; 
import "./EventsList.css"; // Updated class name and CSS file name

function EventList() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null); // State for selected event
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'events'));
        const eventsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEvents(eventsData);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleShowModal = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  return (
    <div className="event-list">
      <h2 className="event-list-title">Upcoming Events</h2>
      {loading ? (
        <p>Loading events...</p>
      ) : (
        <div className="event-list-grid">
          {events.length > 0 ? (
            events.map(event => (
              <EventCard key={event.id} event={event} onViewDetails={handleShowModal} />
            ))
          ) : (
            <p>No events available at the moment.</p>
          )}
        </div>
      )}
      <Modal showModal={showModal} handleClose={handleCloseModal} event={selectedEvent} />
    </div>
  );
}

export default EventList;
