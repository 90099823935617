import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Import your firebase configuration
import { Link } from 'react-router-dom';
import './LatestBlogs.css';  // Updated CSS for styling

const LatestBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchLatestBlogs = async () => {
      try {
        // Query to fetch the latest 4 blogs ordered by 'date'
        const blogsRef = collection(db, 'blogs');
        const q = query(blogsRef, orderBy('date', 'desc'), limit(4));  // Fetch 4 blogs
        const querySnapshot = await getDocs(q);

        const latestBlogs = [];
        querySnapshot.forEach((doc) => {
          latestBlogs.push({ id: doc.id, ...doc.data() });
        });

        setBlogs(latestBlogs);
      } catch (error) {
        console.error('Error fetching latest blogs: ', error);
      }
    };

    fetchLatestBlogs();
  }, []);

  return (
    
    <div className="latest-blogs">
      <h2 className="latestsection-title">Latest Blogs</h2>
      <div className="latestblog-list">
        {blogs.map((blog) => (
          <div key={blog.id} className="latestblog-card">
            <div className="latestblog-card-content">
              <img src={blog.image} alt={blog.title} className="latestblog-image" />
              <div className="latestblog-info">
                <h3 className="latestblog-title">{blog.title}</h3>
                <p className="latestblog-description">{blog.description.substring(0, 100)}... </p>
                <Link to={`/blogs/${blog.id}`} className="read-more">Read More</Link>
              </div>
            </div>
            <div className="latestblog-meta">
              <small className="latestblog-date">{blog.date}</small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestBlogs;
