import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Firebase config
import "./SeasonalHighlights.css";
import {Link} from "react-router-dom"

const SeasonalHighlightsPage = () => {
  const [highlights, setHighlights] = useState([]);

  useEffect(() => {
    const fetchHighlights = async () => {
      const querySnapshot = await getDocs(collection(db, 'highlights'));
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHighlights(data);
    };
    fetchHighlights();
  }, []);

  return (
    <div>
      <h2>Seasonal Highlights</h2>
      <p className='highlight-text'>
        The best part of Nepal is its variety of climates and season-specific destinations. Most tourists visit during spring and autumn for the perfect weather, although some choose to explore during the off-seasons like monsoon. Nepal experiences five major seasons: Spring (Mid March to Mid May), Summer (Mid May to Mid July), Monsoon (Mid July to September), Autumn (Mid September to Mid November), and Winter (Mid November to Mid March). These highlights should help you choose your travel destination based on your preferred season.
      </p>
      <div className="highlights-list">
        {highlights.map(highlight => (
          <div key={highlight.id} className="highlight-card">
            <img src={highlight.imageUrl} alt={highlight.title} />
            <h3>{highlight.title}</h3>
            <p>{highlight.description.slice(0, 70)}... </p>
            <Link to={`/highlights/${highlight.id}`} className="read-more">Read More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeasonalHighlightsPage;
