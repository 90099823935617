import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import Slider from "react-slick";
import './ReviewSection.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MD5 from 'md5';

function ReviewSection() {
    const [reviews, setReviews] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const [date, setDate] = useState('');
    const [rating, setRating] = useState(0);

    useEffect(() => {
        const fetchReviews = async () => {
            const reviewsCollection = collection(db, 'reviews');
            const reviewsSnapshot = await getDocs(reviewsCollection);
            const reviewsList = reviewsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setReviews(reviewsList);
        };

        fetchReviews();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name.trim() || !email.trim() || !text.trim() || !date || rating === 0) {
            alert("Please fill in all fields correctly.");
            return;
        }

        const newReview = {
            name,
            email,
            text,
            date: Timestamp.fromDate(new Date(date)),
            rating
        };

        try {
            await addDoc(collection(db, 'reviews'), newReview);
            setReviews(prev => [...prev, newReview]);
            resetForm();
            alert("Review submitted successfully!");
        } catch (error) {
            console.error("Error adding review: ", error);
            alert("There was an error submitting your review. Please try again.");
        }
    };

    const resetForm = () => {
        setName('');
        setEmail('');
        setText('');
        setDate('');
        setRating(0);
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="review-section">
            <h2>What Our Customers Say</h2>
            <div className="review-flex-container">
                <div className="left">
                    <div className="review-container">
                        <Slider {...settings}>
                            {reviews.length > 0 ? (
                                reviews.map((review, index) => (
                                    <div key={review.id} className="review-card">
                                        <div className={`avatar-container ${index === 1 ? 'highlight' : ''}`}>
                                            <img
                                                src={`https://www.gravatar.com/avatar/${MD5(review.email ? review.email.trim() : 'default@example.com')}?s=64&d=identicon`}
                                                alt={review.name}
                                                className="avatar"
                                            />
                                        </div>
                                        <p className="review-text">"{review.text}"</p>
                                        <div className="reviewer-info">
                                            <h3>{review.name}</h3>
                                            <p className="review-date">
                                                {review.date instanceof Timestamp
                                                    ? review.date.toDate().toLocaleDateString()
                                                    : new Date(review.date).toLocaleDateString()}
                                            </p>
                                            <div className="review-rating">
                                                {Array.from({ length: 5 }, (_, i) => (
                                                    <span key={i} className={`star ${i < review.rating ? 'filled' : ''}`}>
                                                        &#9733;
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No reviews available.</p>
                            )}
                        </Slider>
                    </div>
                </div>
                <div className="right">
                    <div className="review-form-container">
                        <h3>Submit Your Review</h3>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Your Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <textarea
                                placeholder="Your Review"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                required
                            />
                            <input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                            <label>Rating</label>
                            <select className='rating' value={rating} onChange={(e) => setRating(parseInt(e.target.value))} required>
                                <option value="0">Select Rating</option>
                                <option value="1">1 Star</option>
                                <option value="2">2 Stars</option>
                                <option value="3">3 Stars</option>
                                <option value="4">4 Stars</option>
                                <option value="5">5 Stars</option>
                            </select>
                            <button className='add-button' type="submit">Submit Review</button>
                        </form>
                    </div>
                </div>


            </div>
        </div>
    );


}

export default ReviewSection;
