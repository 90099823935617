import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import './SearchBar.css';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const resultsRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const term = searchTerm.trim();

            if (term) {
                const fetchCollections = async () => {
                    const collectionsArray = ['blogs', 'articles', 'events', 'packages'];
                    const allData = [];

                    for (const collectionName of collectionsArray) {
                        const snapshot = await getDocs(collection(db, collectionName));
                        snapshot.docs.forEach(doc => {
                            allData.push({ id: doc.id, ...doc.data(), type: collectionName.slice(0, -1) }); // Singular type
                        });
                    }

                    return allData;
                };

                const allData = await fetchCollections();

                const filteredResults = allData.filter((item) =>
                    Object.values(item).some((value) => String(value).toLowerCase().includes(term.toLowerCase()))
                );

                setResults(filteredResults);
            } else {
                setResults([]);
            }
        };

        fetchData();
    }, [searchTerm]);

    return (
        <div className="search-bar-container">
            <input
                type="text"
                placeholder="Search for articles, blogs, events, and packages..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
            />
            {searchTerm && (
                <div className={`search-results ${results.length > 0 ? 'show' : ''}`} ref={resultsRef}>
                    {results.length > 0 ? (
                        results.map((result) => (
                            <div key={result.id} className="result-item">
                                {result.image && (
                                    <img className="searchImage" src={result.image} alt={`${result.type} image`} />
                                )}
                                
                                {result.type === "event" ? (
                                    <a href={`/${result.type}s`} className="result-link">
                                        <h3 className="result-title">{result.title || result.name}</h3>
                                    </a>
                                ) : (
                                    <a href={`/${result.type}s/${result.id}`} className="result-link">
                                        <h3 className="result-title">{result.title || result.name}</h3>
                                    </a>
                                )}
                                <p className="result-type">{result.type}</p>
                            </div>
                        ))
                    ) : (
                        <p className="no-results">No results found</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchBar;
