import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; 
import { collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore"; // Firestore functions
import "./Packages.css";

const Packages = () => {
    const [packages, setPackages] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [price, setPrice] = useState("");
    const [duration, setDuration] = useState("");
    const [startPoint, setStartPoint] = useState("");
    const [endPoint, setEndPoint] = useState("");
    const [itinerary, setItinerary] = useState([]); 

    const fetchPackages = async () => {
        const packagesCollection = collection(db, "packages");
        const packagesSnapshot = await getDocs(packagesCollection);
        const packagesList = packagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPackages(packagesList);
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handleItineraryChange = (day, value) => {
        const updatedItinerary = [...itinerary];
        updatedItinerary[day - 1] = value;
        setItinerary(updatedItinerary);
    };

    const handleAddPackage = async (e) => {
        e.preventDefault();
        await addDoc(collection(db, "packages"), {
            name,
            description,
            image,
            price,
            duration,
            startPoint,
            endPoint,
            itinerary,
        });
        setName("");
        setDescription("");
        setImage("");
        setPrice("");
        setDuration("");
        setStartPoint("");
        setEndPoint("");
        setItinerary([]);
        fetchPackages();
    };

    const handleDeletePackage = async (id) => {
        await deleteDoc(doc(db, "packages", id));
        fetchPackages();
    };

    return (
        <div className="packages-container">
            <h2 className="packages-header">Manage Packages</h2>
            <form className="package-form" onSubmit={handleAddPackage}>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Package Name"
                    required
                    className="input-field"
                />
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Package Description"
                    required
                    className="textarea-field"
                />
                <input
                    type="text"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                    placeholder="Image URL"
                    required
                    className="input-field"
                />
                <input
                    type="text"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Price"
                    required
                    className="input-field"
                />
                <input
                    type="text"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    placeholder="Duration (in days)"
                    required
                    className="input-field"
                />
                <input
                    type="text"
                    value={startPoint}
                    onChange={(e) => setStartPoint(e.target.value)}
                    placeholder="Start Point"
                    required
                    className="input-field"
                />
                <input
                    type="text"
                    value={endPoint}
                    onChange={(e) => setEndPoint(e.target.value)}
                    placeholder="End Point"
                    required
                    className="input-field"
                />

                {[...Array(parseInt(duration || 0))].map((_, i) => (
                    <textarea
                        key={i}
                        value={itinerary[i] || ""}
                        onChange={(e) => handleItineraryChange(i + 1, e.target.value)}
                        placeholder={`Day ${i + 1} Itinerary`}
                        required
                        className="textarea-field itinerary-field"
                    />
                ))}

                <button type="submit" className="submit-button">Add Package</button>
            </form>

            <ul className="packages-list">
                {packages.map(pkg => (
                    <li key={pkg.id} className="package-item">
                        <h3 className="package-name">{pkg.name}</h3>
                        <img src={pkg.image} alt={pkg.name} className="package-image" />
                        <p className="package-price">Price: {pkg.price}</p>
                        <p className="package-duration">Duration: {pkg.duration} days</p>
                        <ul className="itinerary-list">
                            {(pkg.itinerary || []).map((day, idx) => (
                                <li key={idx} className="itinerary-day">Day {idx + 1}: {day}</li>
                            ))}
                        </ul>
                        <button onClick={() => handleDeletePackage(pkg.id)} className="delete-button">
                            Delete
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Packages;
